import React from 'react';
import { NavigationBar } from './navigationBar';
import { Footer } from './footer'
import { Modals } from './modal';
import { ScriptCDN } from './scriptCDN';
import { resources } from './locales.js'

export function PrivacyContent({ lang }) {

    const disclaimer = []
    if (lang !== resources.defaultTerms) {
        disclaimer.push(
            <>
                <br/><br/>
                <h4>{resources[lang]["privacy"]["disclaimer"]}</h4>
            </>
        )
    }

    return (
        <div>
            <NavigationBar lang={lang}></NavigationBar>

            <div class="container" >
                <div class="row ">
                    <div class="col-12 col-xs-12 col-sm-6 col-md-8 mx-auto mt-5 text-center text-zer-urdin">
                        <h1>{resources[lang]["privacy"]["title"]}</h1>
                        {disclaimer}
                    </div>
                </div>
                    <div class="row  justify-content-left">
                    <div class="col-12 col-xs-12 col-sm-6 col-md-8 mx-auto mt-5 pt-5 ">
                        <p>De conformidad con las leyes de protección de datos, te pedimos que dediques un momento a revisar los puntos clave de la Política de Privacidad de este sitio.</p>
                        <p>El objetivo de esta Política de Privacidad es informarte sobre qué datos recogemos, por qué los recogemos y cómo puedes gestionarlos.</p>
                        <p>Batua.eus (en adelante BATUA) es un servicio de traducción abierta basada en Inteligencia Artificial ofrecida por la Fundación Vicomtech (en adelante Vicomtech) (www.vicomtech.org) a la sociedad con el objetivo de mejorar las tecnologías de traducción en el ámbito del Euskera.</p>
                        <br/><br/>
                        <h2>Datos que tratamos cuando utilizas BATUA:</h2>
                        <br/>
                        <p>Cuando solicitas una traducción de un texto, tratamos información sobre esa solicitud, como por ejemplo el texto que has introducido, las correcciones realizadas, tu valoración, el tipo de dispositivo, la dirección IP de la solicitud, los datos de las cookies y la ubicación.</p>

                        <br/><br/>
                        <h2>Por qué los tratamos (Uso de los datos)</h2>
                        <br/>
                        <p>Empleamos los textos originales como sus traducciones, correcciones y las valoraciones realizadas por los usuarios única y exclusivamente para la mejora de los motores de traducción con los que trabaja el servicio, siempre en el marco de la investigación en Inteligencia Artificial aplicada al lenguaje.</p>
                        <p>De esa forma buscamos mejorar las traducciones que realiza el servicio para así ofrecer un mejor servicio a nuestros usuarios y mejorar el estado del arte de las tecnologías de traducción del Euskera. Este uso es exclusivamente para fines de investigación y la información es tratada tras su anonimización.</p>
                        <p>En resumen, tratamos estos datos con la siguiente finalidad:</p>
                        <ul>
                            <li>Permitir que nuestro servicio ofrezca traducciones más útiles y fiables.</li>
                            <li>Mejorar la calidad de nuestros servicios a través de la mejora de los algoritmos de traducción.</li>
                            <li>Mejorar la seguridad protegiendo al servicio de usos inadecuados, fraudulentos y abusos que impidan que el servicio pueda ser utilizado por todos los usuarios que lo requieran.</li>
                            <li>Llevar a cabo analíticas y mediciones para averiguar cómo se utiliza nuestro servicio para así poder detectar e implementar mejoras.</li>
                            <li>Llevar a cabo tareas de monitorización ayude a garantizar la disponibilidad del servicio evitando cortes en el servicio.</li>
                        </ul>

                        <br/><br/>
                        <h2>Aceptación</h2>
                        <br/>
                        <p>Aceptación expresa: Como condición para el uso de este servicio se colititará una aceptación expresa tanto de la política de privacidad como de los términos de uso.</p>
                        <p>Aceptación por uso: El uso de este servicio expresa la aceptación de esta política de privacidad y los términos de uso al estar ambos ligados y ser necesarios para la consecución del objetivo de investigación científica de este servicio.</p>

                        <br/><br/>
                        <h2>Cuando compartimos tu información</h2>
                        <br/>

                        <p>No compartimos tu información con empresas, organizaciones o individuos ajenos a BATUA (o Vicomtech) excepto en los siguientes casos:</p>
                        <br/>
                        <h4>a. Motivos legales</h4>
                        <p>Compartiremos información personal de forma externa a Vicomtech si consideramos de buena fe que es razonablemente necesario acceder a esos datos o utilizarlos, conservarlos o revelarlos con los siguientes propósitos:</p>
                        <ul>
                            <li>Cumplir con cualquier requisito previsto en la ley o la normativa aplicables o para atender un proceso legal o un requerimiento de una autoridad competente.</li>
                            <li>Cumplir las condiciones de servicio aplicables, incluida la investigación de posibles infracciones a abusos.</li>
                            <li>Detectar, prevenir o solucionar de otra forma fraudes o problemas de seguridad o técnicos.</li>
                            <li>Proteger a BATUA, a nuestros usuarios y al público en general de daños a sus derechos y bienes o a su seguridad en la medida exigida o permitida por la ley.</li>
                        </ul>
                        <p>Si BATUA participa en una fusión, adquisición o venta de activos, seguiremos garantizando la confidencialidad de tu información personal y lo haremos saber mediante un aviso en el servicio antes de transferir esta información o antes de que quede sometida a una política de privacidad diferente.</p>
                        <br/>
                        <h4>b. Publicación de estadísticas</h4>
                        <p>Es posible que compartamos información personal no identificable públicamente para mostrar tendencias y estadísticas sobre el uso general de nuestro servicio tanto a través de los medios de comunicación como redes sociales.</p>

                        <br/><br/>
                        <h2>Protección de tu información</h2>
                        <br/>

                        <p>Integramos la seguridad en nuestros servicios para proteger la información de nuestros usuarios</p>
                        <p>BATUA incluye protecciones de seguridad sólidas que protegen constantemente tu información. Para ello:</p>
                        <ul>
                            <li>Utilizamos cifrado con el objetivo de mantener la privacidad de los datos de los usuarios mientras están en tránsito.</li>
                            <li>Revisamos nuestras prácticas de recogida, almacenamiento y tratamiento de datos (incluidas las medidas de seguridad físicas) para evitar el acceso no autorizado a nuestros sistemas.</li>
                            <li>Restringimos el acceso a la información personal para que solo puedan acceder a ella aquellos empleados y agentes de Vicomtech que necesitan dicha información para procesarla.</li>
                        </ul>

                        <br/><br/>
                        <h2>Cumplimiento y cooperación con autoridades reguladoras</h2>
                        <br/>
                        <p>Revisamos periódicamente esta Política de Privacidad y nos aseguramos de tratar la información de conformidad con ella.</p>

                        <br/><br/>
                        <h2>Transferencias de datos</h2>
                        <br/>
                        <p>Nuestros servidores se encuentran dentro de UE.</p>
                        <p>Si por necesidades operativas o para asegurar un nivel de calidad del servicio fuera necesario disponer de cierta infraestructura en otras ubicaciones con menor protección hacia la privacidad de los usuarios, implementamos las mismas medidas de protección que se describen en esta política. </p>

                        <br/><br/>
                        <h2>Cuando se aplica esta política</h2>
                        <br/>

                        <p>Esta Política de Privacidad se aplica a todos los servicios proporcionados por BATUA tanto en web como en dispositivos móviles.</p>
                        <br/><br/>
                    </div>
                </div>
            </div>

            <Modals lang={lang}></Modals>
            <Footer lang={lang}></Footer>
            <ScriptCDN lang={lang} page="privacy"></ScriptCDN>
        </div>
    );
}
